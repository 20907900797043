import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-basic-popup',
  templateUrl: './basic-popup.component.html',
  styleUrls: ['./basic-popup.component.css']
})
export class BasicPopupComponent implements OnInit {
  pageType = '';
  formInput: FormGroup;
  pageTitle = '';
  cancelForm: FormGroup;
  
  constructor(
    @Optional() public dialogRef: MatDialogRef<any>, 
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, 

      private formBuilder: FormBuilder
    , private spinner: NgxSpinnerService
    , private _api: ApiService
    ) {
      this.formInput = this.formBuilder.group({
        mother_maiden_name: new FormControl(''),
    });
    this.cancelForm = this.formBuilder.group({
      reason: new FormControl(''),
  });
  }

  ngOnInit(): void {
    this.pageType = this.data['pageType'];
    this.pageTitle = this.data['pageTitle'];
  }

  close() {
    this.dialogRef.close();
  }

  goToHome(){
    this.dialogRef.close(true);
  }

  submit() {
    let mother_maiden_name = this.formInput.get('mother_maiden_name')?.value;
    if(!mother_maiden_name){
      this._api.openSnackBar("Enter Mother's Maiden Name", 'Error');
      return
    }
    if (this.formInput.valid) {
      this.dialogRef.close(mother_maiden_name);
    }
  }

  cancelApp() {
    let reason = this.cancelForm.get('reason')?.value;
    if(!reason){
      this._api.openSnackBar("Enter Reason", 'Error');
      return
    }
    if (this.cancelForm.valid) {
      this.dialogRef.close(reason);
    }
  }
}
