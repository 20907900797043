import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-custom-file',
  template: `
  <div class="file-input-wrapper">
      <label [for]="id" class="file-label">{{ to.label }}</label>
      <input
        type="file"
        [formControl]="to['formControl']"
        [formlyAttributes]="field"
        [multiple]="to['multiple']"
        [accept]="to['accept']"
        id="{{ id }}"
        class="file-input"
        (change)="onFileSelect($event)"
      />
      <div class="file-description" *ngIf="to.description">{{ to.description }}</div>
      <!-- <div class="file-names" *ngIf="selectedFiles.length > 0">
        Selected files:
        <ul>
          <li *ngFor="let file of selectedFiles">{{ file.name }}</li>
        </ul>
      </div> -->
      <mat-error *ngIf="showError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </div>
  `,
  styles: [
    `
      .file-input-wrapper {
        display: flex;
        flex-direction: column;
      }
      .file-label {
        font-weight: normal;
        margin-bottom: 5px;
        color: #0d404d;
      }
      .file-input {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        cursor: pointer;
      }
      .file-description {
        font-size: 0.85rem;
        color: #777;
        margin-top: 5px;
      }
      .file-names {
        font-size: 0.85rem;
        color: #333;
        margin-top: 10px;
      }
      .file-names ul {
        padding-left: 20px;
      }
    `,
  ],
})
export class FormlyFileCustom extends FieldType {
    
  selectedFiles: File[] = [];

  onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.formControl.setValue(input.files);
      this.selectedFiles = Array.from(input.files);
    } else {
      this.formControl.setValue(null);
    }
  }
    // const input = event.target as HTMLInputElement;
    // if (input.files) {
    //   this.selectedFiles = Array.from(input.files);
    // }
}
